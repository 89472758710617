import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { history } from "config/history";
import { clearAuthTokens } from "utilities/auth";

const Logout = () => {
  const { t } = useTranslation();
  const hasMounted = useRef(false);
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      const asyncWrapper = async () => {
        try {
          await clearAuthTokens();
        } catch (error) {
          // throw new Error("Logout failed without a known reason");
        }
      };
      asyncWrapper();
    }
  }, []);
  return (
    <div className="outside__content__form">
      <div>
        <h1 className="display-4 tw-mb-12">{t("Logout")}</h1>
        <div className="spinner-fixture">
          {t("You have been logged out.")}{" "}
          <a
            href="/login"
            onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}
          >
            {t("Link to login")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Logout;
